<template>
  <customer-sms-blast />
</template>

<script>
import CustomerSmsBlast from '@/components/pages/customer/CustomerSmsBlast'

export default {
  components: {
    CustomerSmsBlast
  }
}
</script>
